.controls {
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.controls--small {
  padding: 10px 25px 0;
}
.controls > br {
  display: none;
}