html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

body, #root {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

svg {
  width: 100%;
  width: calc(100% - 25px);
  margin-left: 25px;
}