.logo {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.changelog {
  text-decoration: none;
  cursor: pointer;
}
.changelog .changelog__chip {
  cursor: pointer;
}
.fullscreen {
  margin-left: auto;
  margin-right: 15px;
  cursor: pointer;
}
.footer {
  margin-top: auto;
  padding: 25px;
  display: flex;
  justify-content: space-between;
}
.footer--small {
  padding: 10px;
}
.play {
  margin-top: auto;
  text-align: center;
}
.play--small {
  margin-top: auto;
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.flags {
  width: 100px;
}
